import React, { useState } from 'react'
import { Box } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import ConnectVehicleSelector from '../../Vehicle/ConnectVehicleSelector'
import ConnectDeviceInformationCard from '../../ConnectDeviceInformationCard'
import Lottie from 'lottie-react'
import data from './resources/data.json'
import connectVehicleAnimation from './resources/images/connect-vehicle-lottie.json'
import { useUrlSearchParam } from '@/hooks'
import { useNavigation } from '@/app/hooks'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(() =>
  createStyles({
    vehicleSelectionArea: {
      maxHeight: 'none',
    },
  }),
)

const ConnectVehicleAnimation = (
  <Lottie animationData={connectVehicleAnimation} loop />
)

interface Classes {
  vehicleSelector?: string
}

export interface Props {
  onTeslaConnectionHappened: () => void
  onTeslaConnectionEnd: () => void
  awaitingTeslaConfirmation: boolean
  classes?: Classes
  onClose?: () => void
  onSkip?: () => void
  onFinish: () => void
}

const ConnectVehicleCard = ({
  classes: providedClasses,
  onSkip,
  onFinish,
}: Props) => {
  const skipValueProp = useUrlSearchParam('value_prop') !== 'true'
  const [showVehicleSelector, setShowVehicleSelector] = useState(skipValueProp)
  const classes = useStyles()
  const history = useHistory()

  if (showVehicleSelector) {
    if (history.location.search.includes('value_prop=true')) {
      history.replace(history.location.pathname)
    }
    return (
      <ConnectVehicleSelector
        selectorAreaStyle={classes.vehicleSelectionArea}
        onFinish={onFinish}
      />
    )
  }
  return (
    <ConnectDeviceInformationCard
      id="connect-vehicle-information-card"
      onboarding
      headerlessOnDesktop={false}
      media={ConnectVehicleAnimation}
      title={data.title}
      subtitle={data.subtitle}
      informationCards={data.informationCards}
      onClick={() => {
        setShowVehicleSelector(true)
        window.scrollTo(0, 0)
      }}
      onSkipClick={onSkip}
      padContent
    />
  )
}

export default ConnectVehicleCard
