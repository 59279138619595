import ConnectVehicleCard from '@/authenticated/components/Onboarding/ConnectVehicleCard'
import { useAppDispatch } from '@/hooks'
import { usePostAuthRedirectUrl } from '@/hooks/useDeviceSelectionRedirectUrl'
import { utilityProgramEnrollmentCollection } from '@/reducers/utilityProgramEnrollments'
import { vehicleEnrollmentCollection } from '@/reducers/deviceProgramEnrollments'
import { useHistory } from 'react-router-dom'
import { surveyResponsesCollection } from '@/reducers/surveyResponses'
import { utilityProgramCollection } from '@/reducers/utilityPrograms'
import NavigationPage from '@/app/components/NavigationPage'

export default function ConnectVehicle() {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const onFinishRedirect = usePostAuthRedirectUrl()

  const onFinish = () => {
    dispatch(utilityProgramEnrollmentCollection.actions.invalidate())
    dispatch(utilityProgramCollection.actions.invalidate())
    dispatch(vehicleEnrollmentCollection.actions.invalidate())
    dispatch(surveyResponsesCollection.actions.fetch())
    history.push(onFinishRedirect)
  }

  return (
    <NavigationPage id="connect-vehicle">
      <NavigationPage.SingleCardContent>
        <ConnectVehicleCard
          onFinish={onFinish}
          onTeslaConnectionEnd={onFinish}
          onTeslaConnectionHappened={() => {}}
          awaitingTeslaConfirmation={false}
        />
      </NavigationPage.SingleCardContent>
    </NavigationPage>
  )
}
